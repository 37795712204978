import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AppContext } from '../../lib/context'
import { WIDTH_MOBILE } from '../../constants'
import { getImageUrlFromFullPath, getObjectImages } from '../../fb/fbstorage'
import DotsNavigation from '../DotsNavigation'
import HistoryCard from '../HistoryCard'
import HistoryBlur from '../HistoryBlur'
import HistoryForwardButton from '../HistoryForwardButton'
import { objectInputProps } from './Upload'
import { NotImplemented } from './Login'

const formatProps = (objectProp: string, objectPropValue: any) => {
    // console.log(objectProp, typeof objectPropValue, objectPropValue))
    if (objectProp.includes('DATE')) {
        const date = new Date(objectPropValue.seconds)
        // console.log(objectPropValue.seconds, date)
        return date.toISOString().slice(0, 10)
    }
    return objectPropValue
}

export default function ArtworkItemDetail() {
    const { userOwns, selectedObjectId } = useContext(AppContext)
    const objectData = useMemo(
        () => userOwns.find((object) => object.OBJECT_ID === selectedObjectId),
        [userOwns, selectedObjectId]
    )
    // console.log(objectData)

    const [imgBlobUrl, setImgBlobUrl] = useState<string>()

    useEffect(() => {
        getObjectImages(objectData.OBJECT_ID).then((imageList) => {
            const mainImages = imageList.filter((image) =>
                image.name.includes('main')
            )
            if (mainImages.length > 1) {
                try {
                    const resized = mainImages.filter((image) =>
                        image.name.includes('resized_1440')
                    )[0]
                    getImageUrlFromFullPath(resized.fullPath).then(
                        setImgBlobUrl
                    )
                } catch (error) {}
            } else if (mainImages.length === 1) {
                getImageUrlFromFullPath(mainImages[0].fullPath).then(
                    setImgBlobUrl
                )
            } else if (imageList.length > 0) {
                getImageUrlFromFullPath(imageList[0].fullPath).then(
                    setImgBlobUrl
                )
            }
        })
    }, [])

    return (
        <div style={{ marginTop: 20 }}>
            <div
                style={{
                    width: WIDTH_MOBILE,
                }}
            >
                <div
                    style={{
                        marginLeft: 16,
                        width: WIDTH_MOBILE - 16,

                        fontSize: 16,
                        fontWeight: 700,
                    }}
                >{`작품명: ${objectData.OBJECT_NAME}`}</div>
                <img
                    style={{
                        marginLeft: 16,
                        marginTop: 12,
                        borderRadius: 10,
                        objectFit: 'cover',
                    }}
                    src={
                        imgBlobUrl ||
                        'https://www.notion.so/icons/color-palette_lightgray.svg?mode=light'
                    }
                    width={328}
                    height={321}
                />
                <div
                    style={{
                        marginTop: 14,
                    }}
                >
                    <DotsNavigation />
                </div>
            </div>
            <div
                style={{
                    width: WIDTH_MOBILE,
                    marginTop: 20,
                }}
            >
                <div
                    style={{
                        marginLeft: 16,
                        width: WIDTH_MOBILE - 16,

                        fontSize: 16,
                        fontWeight: 700,
                    }}
                >{`작품 소유 이력`}</div>
                <div
                    style={{
                        marginTop: 12,
                        position: 'relative',
                    }}
                >
                    <HistoryCard />
                    <div
                        style={{
                            position: 'absolute',
                            top: 63,
                            right: 32,
                        }}
                    >
                        <DotsNavigation direction="column" />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 16,
                        }}
                    >
                        <HistoryBlur />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: 78,
                            left: 88,
                        }}
                    >
                        <HistoryForwardButton onClick={NotImplemented} />
                    </div>
                </div>
            </div>
            <div
                style={{
                    width: WIDTH_MOBILE,
                    marginTop: 20,
                }}
            >
                <div
                    style={{
                        marginLeft: 16,
                        width: WIDTH_MOBILE - 16,

                        fontSize: 16,
                        fontWeight: 700,
                    }}
                >{`작품 상세 정보`}</div>
                <div
                    style={{
                        marginTop: 12,
                        marginLeft: 16,
                        marginBottom: 32,

                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                    }}
                >
                    {Object.keys(objectInputProps)
                        .filter(
                            (objectPropName) => objectPropName !== 'OBJECT_NAME'
                        )
                        .map((objectPropName, i) => {
                            return (
                                <div
                                    key={`objectDetail-${i}-${objectPropName}`}
                                >
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 700,
                                            width: 119,
                                            display: 'inline-block',
                                        }}
                                    >
                                        {
                                            objectInputProps[objectPropName]
                                                .displayLable
                                        }
                                    </span>
                                    <span
                                        style={{
                                            fontSize: 14,
                                        }}
                                    >
                                        {objectData[objectPropName]}
                                    </span>
                                </div>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}
