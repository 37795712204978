import React, { useMemo, useState, useRef, useEffect } from 'react'

const uploadImageUrl = '/assets/upload-image-add.png'

export default function useImageInput({
    fileInputRef,
}: {
    fileInputRef: React.RefObject<HTMLInputElement>
}) {
    const [imgFile, setImgFile] = useState<File>()
    const [imgBlobUrl, setImgBlobUrl] = useState<string>(uploadImageUrl)

    useEffect(() => {
        if (fileInputRef.current === null) return

        const handler = (e: Event) => {
            // console.log(e)
            const file = (e.target as HTMLInputElement).files?.[0]
            if (!file) return setImgBlobUrl(uploadImageUrl)

            const url = URL.createObjectURL(file)
            setImgBlobUrl(url)
            setImgFile(file)
        }

        fileInputRef.current?.addEventListener('change', handler)

        return () => {
            fileInputRef.current?.removeEventListener('change', handler)
        }
    }, [])

    return {
        imgFile,
        imgBlobUrl,
    }
}
