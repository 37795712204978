import React from 'react'

const ArtworkItemAddButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <div
            style={{
                paddingLeft: 16,
                marginBottom: 20,
            }}
            onClick={onClick}
        >
            <div
                style={{
                    width: 156,
                    height: 156,
                }}
            >
                <img
                    style={{
                        cursor: 'pointer',
                    }}
                    src="/assets/dashboard-artwork-add.svg"
                    alt="작품 추가 버튼"
                />
            </div>
            <div
                style={{
                    marginTop: 6,
                }}
            >
                <div // 작품제목
                    style={{
                        fontSize: 14,
                        fontWeight: 400,
                    }}
                >
                    {' '}
                </div>
                <div // 작가이름(TODO: 작품 데이터 불러올 때 creator_id 로 같이 가져와야 함), 제작년도
                    style={{
                        marginTop: 2,

                        color: '#868686',
                        fontFamily: 'roboto',
                        fontSize: 14,
                        fontWeight: 400,
                    }}
                >
                    {' '}
                </div>
                <div // 소유자, 갤러리 이름 TODO: 필요한가?
                    style={{
                        marginTop: 4,

                        color: '#868686',
                        fontFamily: 'roboto',
                        fontSize: 12,
                        fontWeight: 400,
                    }}
                >
                    {' '}
                </div>
                <div // 작품 가격 TODO: 히스토리에서 가져와야 함
                    style={{
                        marginTop: 4,

                        fontFamily: 'roboto',
                        fontSize: 12,
                        fontWeight: 400,
                    }}
                >
                    {' '}
                </div>
            </div>
        </div>
    )
}

export default ArtworkItemAddButton
