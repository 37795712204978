import React, { useContext } from 'react'
import { loginApple, loginGoogle } from '../../fb/fbauth'
import { NAV_HEIGHT, WIDTH_MOBILE } from '../../constants'
import { AppContext } from '../../lib/context'

export const NotImplemented = () => {
    alert('준비중입니다.')
}

const Login = () => {
    const { waiting, setUserInfo } = useContext(AppContext)
    return (
        <div
            style={{
                width: WIDTH_MOBILE,
                height: `calc(100vh - ${NAV_HEIGHT}px)`,

                // border: '1px solid #eaeaea',

                display: 'flex',
                // justifyContent: 'center',
                marginTop: 20,
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <img
                style={{ borderRadius: 10 }}
                src="/assets/landing-main.png"
                alt="landing"
                width={280}
                height={488}
            />
            {waiting ? (
                <div
                    style={{
                        marginBlock: 24,
                        fontSize: 38,
                        fontWeight: 800,
                        color: '#999999',
                    }}
                >
                    0772
                </div>
            ) : (
                <>
                    <div
                        style={{
                            marginTop: 20,
                            width: WIDTH_MOBILE,
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 16,
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src="/assets/login-dash.svg"
                            alt="dash"
                            width={109}
                        />
                        <span
                            style={{
                                fontSize: 16,
                                fontWeight: 500,
                            }}
                        >
                            소셜 로그인
                        </span>
                        <img
                            src="/assets/login-dash.svg"
                            alt="dash"
                            width={109}
                        />
                    </div>
                    <div
                        style={{
                            marginTop: 30,
                            width: WIDTH_MOBILE,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: 14,
                        }}
                    >
                        <img
                            src="/assets/login-button-google.svg"
                            alt="google"
                            width={44}
                            onClick={() => {
                                loginGoogle(setUserInfo)
                            }}
                            style={{ cursor: 'pointer' }}
                        />
                        <img
                            src="/assets/login-button-facebook.svg"
                            alt="facebook"
                            width={44}
                            onClick={NotImplemented}
                        />
                        <img
                            src="/assets/login-button-kakao.svg"
                            alt="kakao"
                            width={44}
                            onClick={NotImplemented}
                        />
                        <img
                            src="/assets/login-button-naver.svg"
                            alt="naver"
                            width={44}
                            onClick={NotImplemented}
                        />

                        <img
                            src="/assets/login-button-apple.svg"
                            alt="apple"
                            width={44}
                            onClick={() => {
                                loginApple(setUserInfo)
                            }}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default Login
