import React, { useContext, useEffect, useState } from 'react'
import { getImageUrlFromFullPath, getObjectImages } from '../fb/fbstorage'
import { ArtworkObject } from '../model/Object'
import { AppContext } from '../lib/context'

const ArtworkItem = ({ objectData }: { objectData: ArtworkObject }) => {
    const { setPage, setSelectedObjectId } = useContext(AppContext)
    // const [detailOpen, setDetailOpen] = useState<boolean>(false)
    const [imgBlobUrl, setImgBlobUrl] = useState<string>()

    useEffect(() => {
        getObjectImages(objectData.OBJECT_ID).then((imageList) => {
            // console.log(imageList)
            const mainImages = imageList.filter((image) =>
                image.name.includes('main')
            )
            if (mainImages.length > 1) {
                try {
                    const resized = mainImages.filter((image) =>
                        image.name.includes('resized_720')
                    )[0]
                    getImageUrlFromFullPath(resized.fullPath).then(
                        setImgBlobUrl
                    )
                } catch (error) {}
            } else if (mainImages.length === 1) {
                getImageUrlFromFullPath(mainImages[0].fullPath).then(
                    setImgBlobUrl
                )
            } else if (imageList.length > 0) {
                getImageUrlFromFullPath(imageList[0].fullPath).then(
                    setImgBlobUrl
                )
            }
        })
    }, [])

    return (
        <div
            style={{
                paddingLeft: 16,
                marginBottom: 20,
                cursor: 'pointer',
            }}
            onClick={() => {
                setSelectedObjectId(objectData.OBJECT_ID)
                setPage('detail-artwork')
            }}
        >
            <img
                style={{
                    borderRadius: 10,
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',

                    objectFit: 'cover',
                }}
                src={
                    imgBlobUrl ||
                    'https://www.notion.so/icons/color-palette_lightgray.svg?mode=light'
                }
                width={156}
                height={156}
                // onClick={() => {
                //     setDetailOpen((prev) => !prev)
                // }}
            />
            <div
                style={{
                    marginTop: 6,
                }}
            >
                <div // 작품제목
                    style={{
                        fontSize: 14,
                        fontWeight: 400,
                    }}
                >
                    {objectData.OBJECT_NAME || '이름없음'}
                </div>
                <div // 작가이름(TODO: 작품 데이터 불러올 때 creator_id 로 같이 가져와야 함), 제작년도
                    style={{
                        marginTop: 2,

                        color: '#868686',
                        fontFamily: 'roboto',
                        fontSize: 14,
                        fontWeight: 400,
                    }}
                >
                    {`${
                        objectData.OBJECT_NAME || '작가이름없음'
                    }(${objectData.OBJECT_PROD_END_DATE.slice(0, 4)})`}
                </div>
                <div // 소유자, 갤러리 이름 TODO: 필요한가?
                    style={{
                        marginTop: 4,

                        color: '#868686',
                        fontFamily: 'roboto',
                        fontSize: 12,
                        fontWeight: 400,
                    }}
                >
                    {objectData.OBJECT_NAME}
                </div>
                <div // 작품 가격 TODO: 히스토리에서 가져와야 함
                    style={{
                        marginTop: 4,

                        fontFamily: 'roboto',
                        fontSize: 12,
                        fontWeight: 400,
                    }}
                >
                    {`US$${objectData.OBJECT_SEQUENCE}`}
                </div>
            </div>

            {/* {detailOpen && <ArtworkItemDetail object={objectData} />} */}
        </div>
    )
}

export default ArtworkItem
