// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBMJJDI5PqHo4cYz-XADKvJlfYYRvsPL3A',
  authDomain: 'artlog-7fbd7.firebaseapp.com',
  projectId: 'artlog-7fbd7',
  storageBucket: 'artlog-7fbd7.appspot.com',
  messagingSenderId: '656008116686',
  appId: '1:656008116686:web:dca14f6f1653fa13a8939f',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
