import React, { useContext, useEffect, useState } from 'react'
import Dashboard from './Dashboard'
import { get0772ObjectsOfUser } from '../../lib/yggr-api'
import { AppContext } from '../../lib/context'

const Landing = () => {
    const { userInfo, setUserOwns, userOwns, setPage } = useContext(AppContext)
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        if (userInfo?.USER_ID) {
            get0772ObjectsOfUser(userInfo?.USER_ID).then((userOwns) => {
                setUserOwns(userOwns)
                setLoading(false)
            })
        }
    }, [userInfo])

    useEffect(() => {
        if (!loading && userOwns.length === 0) setPage('upload')
    }, [userOwns])

    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
            }}
        >
            {userInfo && !loading ? <Dashboard /> : ''}
        </div>
    )
}

export default Landing
