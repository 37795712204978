import { User as fbUser } from 'firebase/auth'
import * as uuid from 'uuid'
import axios from 'axios'

export const signIn0772User = async (
    fbuser: fbUser | { uid: string; displayName: string }
) => {
    // TODO: 이름 없는 경우, 주소 없는 것 등에 대한 처리 필요(어디서 입력 받을지?)
    try {
        const { data } = await axios.post('/0772_USER/login/', {
            USER_ID: fbuser.uid,
            USER_TYPE: 1,
            USER_ORGANIZATION_ID: 'YGGR',
            USER_NAME: fbuser.displayName || '이름없음',
            USER_BIRTH_DATE: new Date().toISOString().slice(0, 10),
            USER_ADDRESS: '주소 별도 입력 필요',
        })

        return data
    } catch (e) {
        console.error('Error login user: ', e)
    }
}

// deprecated
export const loginAndCreateOrUpdate0772User = async (fbuser: fbUser) => {
    const userId = uuid.v4()
    try {
        await axios.post('/0772_USER/register/', {
            USER_ID: userId,
            USER_TYPE: 1,
            USER_ORGANIZATION_ID: '',
            USER_NAME: fbuser.displayName,
            USER_BIRTH_DATE: new Date().toISOString().slice(0, 10),
            USER_ADDRESS: '',
            USER_FIREBASE_UID: fbuser.uid,
        })
    } catch (e) {
        console.error('Error adding document: ', e)
    }
    return userId
}

// deprecated
export const find0772User = async (USER_FIREBASE_UID: string) => {
    const { data } = await axios.post('/0772_USER/firebase-id/', {
        USER_FIREBASE_UID: USER_FIREBASE_UID,
    })

    return data
}

export const register0772Object = async (object: any) => {
    const objectCategory = object.OBJECT_CATEGORY
    if (!objectCategory) {
        throw new Error('objectCategory is required')
    }
    if (!['유화', '수채화', '조각', '파스텔'].includes(objectCategory)) {
        throw new Error(
            'objectCategory must be one of 유화, 수채화, 조각, 파스텔'
        )
    }

    const objectCategoryCode = {
        유화: 'OP',
        수채화: 'WP',
        조각: 'SP',
        파스텔: 'PT',
    }[objectCategory]

    const { data } = await axios.post('/0772_OBJECT/registerObject/', {
        ...object,
        OBJECT_CATEGORY: objectCategoryCode,
    })
    return data
}

export const registerNew0772History = async ({ userId, objectId }) => {
    const { data } = await axios.post('/0772_HISTORY/newHistory/', {
        HISTORY_USER_ID: userId,
        HISTORY_OBJECT_ID: objectId,
        HISTORY_PREV_SEQ: -1,
    })
    return data
}

export const get0772ObjectsOfUser = async (userId: string) => {
    const {
        data: { DATA },
    } = await axios.post('/0772_OBJECT/searchObjectListOfUser/', {
        USER_ID: userId,
    })
    return DATA
}
