export const saveUserToLocalStorage = (uid: string) => {
    localStorage.setItem('artlockUser', uid)
}

export const getUserFromLocalStorage = () => {
    return localStorage.getItem('artlockUser')
}

export const getDisplayNameFromLocalStorage = () => {
    return localStorage.getItem('artlockUserName')
}
