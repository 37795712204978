import React, { useContext, useEffect } from 'react'
import ArtworkItem from '../ArtworkItem'
import ArtworkItemAddButton from '../ArtworkItemAddButton'
import { AppContext } from '../../lib/context'
import { WIDTH_MOBILE } from '../../constants'

const Dashboard = () => {
    const { setPage, userOwns } = useContext(AppContext)

    useEffect(() => {}, [])

    return (
        <div>
            <div style={{ marginTop: 20 }}>
                <div
                    style={{
                        paddingLeft: 20,
                        width: WIDTH_MOBILE - 20,

                        fontSize: 16,
                        fontWeight: 700,
                    }}
                >
                    내 작품 목록
                </div>
                <div
                    style={{
                        marginTop: 9,

                        display: 'flex',
                        width: WIDTH_MOBILE,
                        flexFlow: 'row wrap',
                        // gap: 16,
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}
                >
                    {userOwns.map((userOwnedArtwork, i) => {
                        // console.log(userOwnedArtwork)
                        return (
                            <ArtworkItem
                                key={userOwnedArtwork.OBJECT_ID + i}
                                objectData={userOwnedArtwork}
                            />
                        )
                    })}
                    <ArtworkItemAddButton
                        onClick={() => {
                            setPage('upload')
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    marginTop: 10,
                }}
            >
                <div
                    style={{
                        paddingLeft: 20,
                        width: WIDTH_MOBILE - 20,

                        fontSize: 16,
                        fontWeight: 700,
                    }}
                >
                    추천작품 목록
                </div>
                <div
                    // TODO: 추천작품 목록 가져오기 (현재는 소유 작품 목록 중 랜덤)
                    style={{
                        marginTop: 9,

                        display: 'flex',
                        width: WIDTH_MOBILE,
                        flexFlow: 'row wrap',
                        // gap: 16,
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}
                >
                    {userOwns.length &&
                        [
                            userOwns[
                                Math.floor(Math.random() * userOwns.length)
                            ],
                        ].map((userOwnedArtwork, i) => {
                            // console.log(userOwnedArtwork)
                            return (
                                <ArtworkItem
                                    key={userOwnedArtwork.OBJECT_ID + i}
                                    objectData={userOwnedArtwork}
                                />
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

export default Dashboard
