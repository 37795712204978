import React from 'react'

export default function HistoryCard() {
    return (
        <div
            style={{
                marginLeft: 16,
                width: 328,
                height: 206,

                boxSizing: 'border-box',
                borderRadius: 10,
                border: '1px dashed #868686',
                backgroundColor: '#F1F0F0',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 6,
            }}
        >
            <div
                style={{
                    width: 151,
                }}
            >
                {`소유자 ID : `}
            </div>
            <div
                style={{
                    width: 151,
                }}
            >
                {`소유자 유형 : `}
            </div>
            <div
                style={{
                    width: 151,
                }}
            >
                {`취득일자 : `}
            </div>
        </div>
    )
}
