export function resizeImage(
    file: File,
    minSize: number = 1024
): Promise<{
    resizedFile: File
    resizedWidth: number
    resizedHeight: number
}> {
    const image = new Image()
    const url = URL.createObjectURL(file)

    return new Promise((resolve, reject) => {
        image.onload = () => {
            let canvas = document.createElement('canvas')
            // console.log(canvas)
            // console.log(document.querySelectorAll('canvas'))
            // 캔버스 생성해서 작업하더라도 querySelectorAll로는 캔버스가 잡히지 않음

            const ctx = canvas.getContext('2d')

            if (!ctx) return reject('Error')

            let width = image.width
            let height = image.height

            // console.log(width, height)

            if (width > height) {
                if (height > minSize) {
                    width *= minSize / height
                    height = minSize
                }
            } else {
                if (width > minSize) {
                    height *= minSize / width
                    width = minSize
                }
            }

            // console.log(width, height)

            canvas.width = width
            canvas.height = height

            ctx.drawImage(image, 0, 0, width, height)

            canvas.toBlob((blob) => {
                if (!blob) return reject('resize Error')
                const resizedFile = new File([blob], `resized_${file.name}`, {
                    type: file.type,
                })
                resolve({
                    resizedFile,
                    resizedWidth: width,
                    resizedHeight: height,
                })
            }, file.type)

            canvas = null
        }
        image.src = url
    })
}
