import React from 'react'

export default function HistoryBlur() {
    return (
        <div
            style={{
                width: 328,
                height: 206,
                backgroundColor: 'rgba(36, 36, 36, 0.40)',
                backdropFilter: 'blur(4px)',
                // -webkit-backdrop-filter: blur(5px);
                WebkitBackdropFilter: 'blur(4px)',
                borderRadius: 10,
            }}
        ></div>
    )
}
