import React, { createContext } from 'react'
import { YggrUser } from '../model/User'
import { ArtworkObject } from '../model/Object'

export const AppContext = createContext<{
    isApp: boolean
    setIsApp: React.Dispatch<React.SetStateAction<boolean>>

    page: string
    setPage: React.Dispatch<React.SetStateAction<string>>
    pageHistory: string[]

    waiting: boolean
    setWaiting: React.Dispatch<React.SetStateAction<boolean>>

    menuOn: boolean
    setMenuOn: React.Dispatch<React.SetStateAction<boolean>>

    userInfo: YggrUser | undefined
    setUserInfo: React.Dispatch<React.SetStateAction<YggrUser>>

    userOwns: ArtworkObject[]
    setUserOwns: React.Dispatch<React.SetStateAction<ArtworkObject[]>>

    selectedObjectId: string | undefined
    setSelectedObjectId: React.Dispatch<React.SetStateAction<string>>
}>({
    isApp: false,
    setIsApp: (isApp: boolean) => {},

    page: 'login',
    setPage: (page: string) => {},
    pageHistory: [],

    waiting: false,
    setWaiting: (waiting: boolean) => {},

    menuOn: false,
    setMenuOn: (waiting: boolean) => {},

    userInfo: undefined,
    setUserInfo: (userInfo: YggrUser) => {},

    userOwns: [],
    setUserOwns: (userOwns: ArtworkObject[]) => {},

    selectedObjectId: undefined,
    setSelectedObjectId: (selectedObject: string) => {},
})
