import {
    getAuth,
    signInWithPopup,
    OAuthProvider,
    GoogleAuthProvider,
    signOut,
    browserLocalPersistence,
    setPersistence,
} from 'firebase/auth'
import { signIn0772User } from '../lib/yggr-api'
import { saveUserToLocalStorage } from '../lib/localstorage'

import { app } from './fbinit'
import React from 'react'
import { YggrUser } from '../model/User'

const auth = getAuth(app)
const apple = new OAuthProvider('apple.com')
const google = new GoogleAuthProvider()

export const getCurrentFbUser = () => {
    return auth.currentUser
}

export const loginApple = async (
    setUserInfo: React.Dispatch<React.SetStateAction<YggrUser>>
) => {
    setPersistence(auth, browserLocalPersistence).then(() => {
        signInWithPopup(auth, apple)
            .then(async (result) => {
                // The signed-in user info.
                const user = result.user

                const loginUser = await signIn0772User(user)
                // console.log(loginUser)
                const USER_ID = loginUser.USER_ID

                setUserInfo(loginUser)
                saveUserToLocalStorage(USER_ID)
            })
            .catch((error) => {
                // // Handle Errors here.
                // const errorCode = error.code
                // const errorMessage = error.message
                // // The email of the user's account used.
                // const email = error.customData.email
                // // The credential that was used.
                // const credential = OAuthProvider.credentialFromError(error)
                // // ...
            })
    })
}

export const loginGoogle = (
    setUserInfo: React.Dispatch<React.SetStateAction<YggrUser>>
) => {
    setPersistence(auth, browserLocalPersistence).then(() => {
        signInWithPopup(auth, google)
            .then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                // const credential =
                //     GoogleAuthProvider.credentialFromResult(result)
                // const token = credential.accessToken
                // The signed-in user info.
                const user = result.user
                // ...
                console.log(user)

                const loginUser = await signIn0772User(user)
                // console.log(loginUser)
                const USER_ID = loginUser.USER_ID

                setUserInfo(loginUser)
                saveUserToLocalStorage(USER_ID)
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code
                const errorMessage = error.message
                // The email of the user's account used.
                const email = error.customData.email
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error)
                // ...
            })
    })
}

export const logout = () => {
    signOut(auth).then(() => {
        localStorage.clear()
        window.location.reload()
    })
}
