import React from 'react'

const SelectedDot = () => (
    <div
        style={{
            width: 8,
            height: 8,
            borderRadius: 4,
            backgroundColor: '#242424',
        }}
    ></div>
)

const Dot = () => (
    <div
        style={{
            width: 8,
            height: 8,
            borderRadius: 4,
            backgroundColor: '#242424',
            opacity: 0.3,
        }}
    ></div>
)

export default function DotsNavigation({
    direction = 'row',
}: {
    direction?: 'row' | 'column'
}) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: direction,
                justifyContent: 'center',
                alignItems: 'center',
                gap: 12,
            }}
        >
            <SelectedDot />
            <Dot />
            <Dot />
            <Dot />
        </div>
    )
}
