import React from 'react'

export default function HistoryForwardButton({
    onClick,
}: {
    onClick: () => void
}) {
    return (
        <div
            style={{
                width: 187,
                height: 51,
                borderRadius: 10,
                color: '#fff',
                background: '#242424',
                border: 0,
                outline: 0,

                fontSize: 16,
                fontWeight: 500,

                cursor: 'pointer',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 6,
            }}
            onClick={onClick}
        >
            <span>작품 이력 확인하기</span>
            <img src="/assets/history-button-forward.svg" />
        </div>
    )
}
