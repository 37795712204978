import React, { useContext } from 'react'
import { WIDTH_MOBILE, NAV_HEIGHT } from '../constants'
import { AppContext } from '../lib/context'

export default function NavBar() {
    const { setMenuOn, setPage, page, pageHistory, isApp } =
        useContext(AppContext)

    return (
        <div
            style={{
                marginTop: isApp ? 60 : 0,
                width: WIDTH_MOBILE,
                height: NAV_HEIGHT,
                display: 'flex',
                alignContent: 'center',
                flexDirection: 'row',
                borderBottom:
                    window.location.hostname === 'localhost'
                        ? '1px solid red'
                        : 'none',
            }}
        >
            <div
                style={{
                    width: 18,
                    marginLeft: 20,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {!['landing', 'login'].includes(page) && (
                    <img
                        style={{ cursor: 'pointer' }}
                        src="/assets/nav-back-arrow.svg"
                        alt="nav back arrow"
                        onClick={() => {
                            console.log(pageHistory)
                            if (pageHistory.length > 1) {
                                setPage(pageHistory.slice(-2)[0])
                            }
                        }}
                    />
                )}
            </div>
            <div style={{ width: 278 }} />
            <div
                style={{
                    width: 24,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    style={{ cursor: 'pointer' }}
                    src="/assets/nav-menu.svg"
                    alt="nav menu"
                    onClick={() => setMenuOn(true)}
                />
            </div>
        </div>
    )
}
