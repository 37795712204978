import React, { useState, useEffect } from 'react'
import { isMobile } from 'is-mobile'

import NavBar from './components/NavBar'
import Menu from './components/pages/Menu'
import { YggrUser } from './model/User'
import { ArtworkObject } from './model/Object'
import { AppContext } from './lib/context'
import AppPrep from './components/pages/AppPrep'
import Login from './components/pages/Login'
import Landing from './components/pages/Landing'
import Upload from './components/pages/Upload'
import ArtworkItemDetail from './components/pages/ArtworkItemDetail'
import { getCurrentFbUser } from './fb/fbauth'
import { signIn0772User } from './lib/yggr-api'
import {
    getDisplayNameFromLocalStorage,
    getUserFromLocalStorage,
} from './lib/localstorage'

// const isApp = window.location.hash === '#app'

export default function App() {
    const [isApp, setIsApp] = useState<boolean>(window.location.hash === '#app')
    const [userInfo, setUserInfo] = useState<YggrUser>()
    const [userOwns, setUserOwns] = useState<ArtworkObject[]>([])

    const [page, setPage] = useState<string>('login')
    const [pageHistory, setPageHistory] = useState<string[]>([])
    useEffect(() => {
        setPageHistory([...pageHistory, page])
    }, [page])

    const [waiting, setWaiting] = useState<boolean>(false)
    const [menuOn, setMenuOn] = useState<boolean>(false)

    const [selectedObjectId, setSelectedObjectId] = useState<string>()

    let t = 0

    useEffect(() => {
        // console.log(window.location.hash)
        if (isApp) {
            // console.log('isApp')
            // ;(window as any).Flutter?.postMessage('isApp')
            // console.log(localStorage.length)
            // ;(window as any).Flutter?.postMessage(
            //     'localStorage.length: ' + localStorage.length
            // )
            // ;(window as any).Flutter?.postMessage('appPageReady')
            ;(window as any).loadAppUser = () => {
                if (getUserFromLocalStorage()) {
                    const fbUser = {
                        uid: getUserFromLocalStorage(),
                        displayName: getDisplayNameFromLocalStorage(),
                    }
                    signIn0772User(fbUser).then((loginUser) => {
                        setUserInfo(loginUser)
                    })
                }
            }
        }

        if (getUserFromLocalStorage()) {
            setWaiting(true)
            const fbUserCheck = setInterval(() => {
                const fbUser = getCurrentFbUser()

                if (fbUser) {
                    signIn0772User(fbUser).then((loginUser) => {
                        setUserInfo(loginUser)
                        setWaiting(false)
                    })
                    clearInterval(fbUserCheck)
                }

                if (t > 10) {
                    clearInterval(fbUserCheck)
                    setWaiting(false)
                }

                t += 0.3
            }, 300)
        }
    }, [])

    useEffect(() => {
        // console.log(userInfo)
        if (isApp && !userInfo) setPage('app-prep')
        else if (!userInfo) setPage('login')
        else setPage('landing')
    }, [userInfo])

    return (
        <AppContext.Provider
            value={{
                isApp,
                setIsApp,

                page,
                setPage,
                pageHistory,

                waiting,
                setWaiting,

                menuOn,
                setMenuOn,

                userInfo,
                setUserInfo,

                userOwns,
                setUserOwns,

                selectedObjectId,
                setSelectedObjectId,
            }}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {menuOn && <Menu />}
                <NavBar />
                {page === 'login' ? (
                    <Login />
                ) : page === 'app-prep' ? (
                    <AppPrep />
                ) : page === 'landing' ? (
                    <Landing />
                ) : page === 'upload' ? (
                    <Upload />
                ) : page === 'detail-artwork' ? (
                    <ArtworkItemDetail />
                ) : (
                    ''
                )}
            </div>
        </AppContext.Provider>
    )
}
