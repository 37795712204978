import React, { useContext } from 'react'
import { AppContext } from '../../lib/context'
import {
    getDisplayNameFromLocalStorage,
    getUserFromLocalStorage,
} from '../../lib/localstorage'
import { signIn0772User } from '../../lib/yggr-api'

export default function AppPrep() {
    const { isApp, setUserInfo, userInfo, setPage } = useContext(AppContext)

    React.useEffect(() => {
        if (isApp) {
            ;(window as any).Flutter?.postMessage('appPageReady')
        }

        if (isApp && userInfo) {
            ;(window as any).Flutter?.postMessage('appUserSetted')
            setPage('landing')
        }
    }, [isApp, userInfo])

    return (
        <div>
            <h2>로그인 정보 확인 중</h2>
            {/* (isApp && userInfo? <div>{userInfo.USER_ID}</div>) */}
        </div>
    )
}
