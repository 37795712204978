import React, {
    ChangeEventHandler,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'

import { register0772Object, registerNew0772History } from '../../lib/yggr-api'
import { uploadImageFileWithResized } from '../../fb/fbstorage'
import { ArtworkObject } from '../../model/Object'
import { AppContext } from '../../lib/context'
import { WIDTH_MOBILE } from '../../constants'
import useImageInput from '../hooks/useImageInput'

const Input = ({
    label,
    placeholder,
    value,
    onChange,
    inputType,
    disabled = false,
    select = false,
    selectOptions = [],
}) => {
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState<string>()
    const inputRef = useRef<HTMLInputElement>(null)

    return (
        <div style={{ marginTop: 20, marginLeft: 16, position: 'relative' }}>
            <div>
                <label>{label}</label>
            </div>
            <div>
                <input
                    className="upload"
                    ref={inputRef}
                    style={{
                        marginTop: 8,
                        textAlign: 'left',
                        width: 308,
                        height: 48,
                        borderRadius: 10,
                        fontSize: 16,
                        outline: 0,
                        border: 0,
                        backgroundColor: '#F1F0F0',
                        // color: '#242424 !important',
                        paddingLeft: 20,
                        paddingRight: 0,
                        paddingBlock: 0,
                        boxSizing: 'content-box',
                        position: 'relative',
                    }}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    type={inputType}
                    disabled={disabled}
                />
            </div>
            {select && (
                <img
                    style={{
                        position: 'absolute',
                        top: 43,
                        right: 36,
                        zIndex: 100,
                    }}
                    src={
                        optionsOpen
                            ? '/assets/input-arrow-fold.svg'
                            : '/assets/input-arrow-unfold.svg'
                    }
                    onClick={() => setOptionsOpen((prev) => !prev)}
                    alt="펼치기"
                    width={24}
                    height={24}
                />
            )}

            {select && optionsOpen && selectOptions.length > 0 && (
                <div
                    style={{
                        position: 'absolute',
                        top: 87,
                        zIndex: 101,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',

                            width: 328,
                            background: '#F1F0F0',
                            borderRadius: 10,
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        {selectOptions.map((option, i) => (
                            <div
                                key={`option-${i}-${option}`}
                                style={{
                                    fontSize: 16,
                                    cursor: 'pointer',
                                    paddingLeft: 20,
                                    paddingBlock: 12,
                                    borderTop:
                                        i === 0 ? 0 : '1px solid #DEDEDE',
                                }}
                                onClick={() => {
                                    if (inputRef.current) {
                                        inputRef.current.value = option
                                        onChange({ target: inputRef.current })
                                    }
                                    setSelectedOption(option)
                                    setOptionsOpen(false)
                                }}
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

// const useTextInput: () => [
//     string,
//     ChangeEventHandler<HTMLInputElement>
// ] = () => {
//     const [value, setValue] = useState('')
//     const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
//         setValue(e.target.value)
//     }
//     return [value, onChange]
// }

// const useNumberInput: () => [
//     number,
//     ChangeEventHandler<HTMLInputElement>
// ] = () => {
//     const [value, setValue] = useState<number>(0)
//     const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
//         setValue(parseInt(e.target.value))
//     }
//     return [value, onChange]
// }

// const useDateInput: () => [
//     string,
//     ChangeEventHandler<HTMLInputElement>
// ] = () => {
//     const [value, setValue] = useState<string>(
//         new Date().toISOString().slice(0, 10)
//     )
//     const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
//         // console.log(e.target.value)
//         setValue(e.target.value)
//     }
//     return [value, onChange]
// }

const defaultArtworkObjectInputValue: () => ArtworkObject = () => ({
    OBJECT_CATEGORY: '유화',
    OBJECT_NAME: '',
    OBJECT_LENGTH_UNIT: 'CM',
    OBJECT_HEIGHT: 100,
    OBJECT_WIDTH: 100,
    OBJECT_DEPTH: 100,
    OBJECT_OMIT_VAL: 0,
    OBJECT_CREATOR_ID: '',
    OBJECT_RARITY: 'UQ',
    OBJECT_TOTAL_COUNT: 1,
    OBJECT_PUBLISHED_NO: 1,
    OBJECT_PROD_START_DATE: new Date().toISOString().slice(0, 10),
    OBJECT_PROD_END_DATE: new Date().toISOString().slice(0, 10),
    OBJECT_PROD_PLACE: 'SEOUL',
    OBJECT_MATERIAL_ID: 'XX',
})

export const objectInputProps = {
    OBJECT_NAME: {
        inputType: 'numbur',
        displayLable: '작품명',
        disabled: false,
        select: false,
    },
    OBJECT_CATEGORY: {
        inputType: 'text',
        displayLable: '작품유형',
        disabled: true,
        select: true,
        selectOptions: ['유화', '수채화', '조각', '파스텔'],
    },
    OBJECT_LENGTH_UNIT: {
        inputType: 'text',
        displayLable: '길이단위',
        disabled: true,
        select: true,
        selectOptions: ['CM', 'MM', 'M', 'INCH', 'FT', 'YD'],
    },
    OBJECT_WIDTH: {
        inputType: 'number',
        displayLable: '작품크기(Width)',
        disabled: false,
        select: false,
    },
    OBJECT_HEIGHT: {
        inputType: 'number',
        displayLable: '작품크기(Height)',
        disabled: false,
        select: false,
    },
    OBJECT_DEPTH: {
        inputType: 'number',
        displayLable: '작품크기(Depth)',
        disabled: false,
        select: false,
    },
    OBJECT_OMIT_VAL: {
        inputType: 'number',
        displayLable: '작품측정생략값',
        disabled: false,
        select: false,
    },
    OBJECT_CREATOR_ID: {
        inputType: 'text',
        displayLable: '작가ID',
        disabled: true,
        select: false,
    },
    OBJECT_RARITY: {
        inputType: 'text',
        displayLable: '희귀도',
        disabled: false,
        select: false,
    },
    OBJECT_TOTAL_COUNT: {
        inputType: 'number',
        displayLable: '동일품목개수',
        disabled: false,
        select: false,
    },
    OBJECT_PUBLISHED_NO: {
        inputType: 'number',
        displayLable: '발행번호',
        disabled: false,
        select: false,
    },
    OBJECT_PROD_START_DATE: {
        inputType: 'date',
        displayLable: '제작시작일',
        disabled: false,
        select: false,
    },
    OBJECT_PROD_END_DATE: {
        inputType: 'date',
        displayLable: '제작종료일',
        disabled: false,
        select: false,
    },
    OBJECT_PROD_PLACE: {
        inputType: 'text',
        displayLable: '제작장소',
        disabled: false,
        select: false,
    },
    OBJECT_MATERIAL_ID: {
        inputType: 'text',
        displayLable: '작품소재',
        disabled: false,
        select: false,
    },
}

const Upload = () => {
    const { userInfo, setPage } = useContext(AppContext)

    const [artworkObject, setArtworkObject] = useState<ArtworkObject>(
        defaultArtworkObjectInputValue
    )
    useEffect(() => {
        // console.log(artworkObject)
    }, [artworkObject])

    const fileInputRef = useRef<HTMLInputElement>(null)
    const { imgFile, imgBlobUrl } = useImageInput({ fileInputRef })

    const uploadHandler = async () => {
        try {
            const savedObject = await register0772Object(artworkObject)
            // console.log(savedObject.OBJECT_ID, userId)

            const mainImageUploadResult = await uploadImageFileWithResized(
                `artworks/${savedObject.OBJECT_ID}`,
                imgFile,
                'main'
            )

            const history = await registerNew0772History({
                objectId: savedObject.OBJECT_ID,
                userId: userInfo.USER_ID,
            })
            // console.log(history)

            setPage('landing')
        } catch (error) {
            alert('작품 등록에 실패했습니다.')
            console.error(error)
            console.log(error.message)
            console.log(JSON.stringify(error))
        }
    }

    return (
        <div
            style={{
                marginTop: 20,
                marginBottom: 48,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <input
                ref={fileInputRef}
                style={{ display: 'none' }}
                type="file"
                accept="image/gif, image/jpeg, image/png"
            />
            <div
                style={{
                    paddingLeft: 20,
                    width: WIDTH_MOBILE - 20,

                    fontSize: 16,
                    fontWeight: 700,
                }}
            >
                내 작품 등록
            </div>
            <img
                style={{
                    marginTop: 16,
                    marginLeft: 79,
                    borderRadius: 10,

                    cursor: 'pointer',
                    objectFit: 'cover',
                }}
                src={imgBlobUrl || '/assets/upload-image-add.png'}
                width={203}
                height={203}
                onClick={() => {
                    fileInputRef.current?.click()
                }}
            />

            {Object.keys(objectInputProps)
                // .filter((objectPropName) => objectPropName in objectInputProps)
                .map((objectPropName, i) => {
                    return (
                        <Input
                            key={`input-${i}-${objectPropName}`}
                            label={
                                objectInputProps[objectPropName].displayLable
                            }
                            placeholder={objectPropName}
                            onChange={(e) => {
                                // console.log(e)
                                // console.log(e.target.value)
                                setArtworkObject((prev) => ({
                                    ...prev,
                                    [objectPropName]: e.target.value,
                                }))
                            }}
                            value={artworkObject[objectPropName]}
                            inputType={
                                objectInputProps[objectPropName].inputType
                            }
                            disabled={objectInputProps[objectPropName].disabled}
                            select={objectInputProps[objectPropName].select}
                            selectOptions={
                                objectInputProps[objectPropName].selectOptions
                            }
                        />
                    )
                })}

            <button
                style={{
                    marginTop: 38,
                    marginLeft: 16,

                    width: 328,
                    height: 51,
                    borderRadius: 10,
                    color: '#fff',
                    background: '#242424',
                    border: 0,
                    outline: 0,

                    fontSize: 16,
                    fontWeight: 700,

                    cursor: 'pointer',
                }}
                onClick={uploadHandler}
            >
                작품 등록
            </button>
        </div>
    )
}

export default Upload
