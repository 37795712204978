import {
    UploadResult,
    getDownloadURL,
    getStorage,
    ref,
    uploadBytes,
    list,
} from 'firebase/storage'
import { app } from './fbinit'
import { resizeImage } from '../lib/resizeImage'

const storage = getStorage(app)

// deprecated
export const uploadFileTest = async (
    uploadPath: string,
    uploadResultHandler: (uploadResult: UploadResult) => void
) => {
    const pickerOptions = {
        types: [
            {
                description: 'Images',
                accept: {
                    'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
                },
            },
        ],
        excludeAcceptAllOption: true,
        multiple: false,
    }

    const [fileHandle] = await (window as any).showOpenFilePicker(pickerOptions)
    const file = await fileHandle.getFile()
    // console.log(file)

    const artworkImgRef = ref(storage, `${uploadPath}/${file.name}`)
    const uploadResult = await uploadBytes(artworkImgRef, file)
    uploadResultHandler(uploadResult)
}

export const uploadFile = async (
    uploadPath: string,
    file: File,
    filename: string = null
) => {
    const artworkImgRef = ref(storage, `${uploadPath}/${filename || file.name}`)
    const uploadResult = await uploadBytes(artworkImgRef, file, {
        contentType: file.type,
        customMetadata: {
            contentType: file.type,
            rawFilename: file.name,
        },
    })
    return uploadResult
}

export const uploadImageFileWithResized = async (
    uploadPath: string,
    file: File,
    filename: string = null
) => {
    const artworkImgRef = ref(storage, `${uploadPath}/${filename || file.name}`)
    const uploadResult = await uploadBytes(artworkImgRef, file, {
        contentType: file.type,
        customMetadata: {
            contentType: file.type,
            rawFilename: file.name,
        },
    })

    const sizes = [360, 720, 1440]
    const resizedUploadResults = await Promise.all(
        sizes.map(async (size) => {
            const { resizedFile, resizedWidth, resizedHeight } =
                await resizeImage(file, size)
            const resizedUploadResult = await uploadBytes(
                ref(
                    storage,
                    `${uploadPath}/resized_${size}_${filename || file.name}`
                ),
                resizedFile,
                {
                    contentType: file.type,
                    customMetadata: {
                        contentType: file.type,
                        rawFilename: file.name,
                        width: resizedWidth.toString(),
                        height: resizedHeight.toString(),
                    },
                }
            )
            return resizedUploadResult
        })
    )

    return uploadResult
}

// deprecated
export const getUrlFromUploadResult = async (uploadResult: UploadResult) => {
    return await getDownloadURL(ref(storage, uploadResult.metadata.fullPath))
}

export const getObjectImages = async (objectId: string) => {
    const artworkImgRef = ref(storage, `artworks/${objectId}/`)
    const artworkImgList = await list(artworkImgRef)
    return artworkImgList.items
}

export const getImageUrlFromFullPath = async (fullPath: string) => {
    return await getDownloadURL(ref(storage, fullPath))
}
