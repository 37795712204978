import React, { useContext } from 'react'
import { AppContext } from '../../lib/context'
import { logout } from '../../fb/fbauth'
import { NotImplemented } from './Login'

const MenuRow = ({
    iconAssetPath,
    title,
    onClick = () => {},
}: {
    iconAssetPath: string
    title: string
    onClick?: () => void
}) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: 30,
                gap: 12,
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <img src={iconAssetPath} alt={title} width={24} height={24} />
            <div style={{ fontSize: 20, fontWeight: 500 }}>{title}</div>
        </div>
    )
}

export default function Menu() {
    const { setMenuOn, isApp } = useContext(AppContext)
    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    zIndex: 50,
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100vh',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                }}
            ></div>
            <div
                style={{
                    position: 'absolute',
                    zIndex: 100,
                    top: isApp ? 60 : 0,
                    left: 0,
                    width: 'calc(50% - 180px + 254px)',
                    height: '100vh',
                    borderTopRightRadius: 30,
                    borderBottomRightRadius: 30,
                    backgroundColor: '#242424',
                    display: 'flex',
                    flexDirection: 'column',
                    color: '#fff',
                }}
            >
                <div
                    style={{
                        paddingLeft: 'calc(50vw - 180px + 40px)',
                        paddingTop: 60,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                    }}
                >
                    <MenuRow
                        iconAssetPath="/assets/menu-artwork-search.svg"
                        title="작품 검색"
                        onClick={NotImplemented}
                    />
                    <MenuRow
                        iconAssetPath="/assets/menu-creator-search.svg"
                        title="작가 검색"
                        onClick={NotImplemented}
                    />
                </div>
                <div
                    style={{
                        paddingLeft: 'calc(50vw - 180px + 40px)',
                        paddingTop: 45,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                    }}
                >
                    <MenuRow
                        iconAssetPath="/assets/menu-mypage.svg"
                        title="마이페이지"
                        onClick={NotImplemented}
                    />
                    <MenuRow
                        iconAssetPath="/assets/menu-config.svg"
                        title="설정"
                        onClick={NotImplemented}
                    />
                    <MenuRow
                        iconAssetPath="/assets/menu-logout.svg"
                        title="로그아웃"
                        onClick={() => {
                            logout()
                            if (isApp) {
                                ;(window as any).Flutter?.postMessage('logout')
                            }
                        }}
                    />
                </div>
            </div>
            <img
                style={{
                    position: 'absolute',
                    zIndex: 101,
                    top: isApp ? 80 : 20,
                    left: 'calc(50vw - 180px + 210px)',
                    cursor: 'pointer',
                }}
                src="/assets/menu-x.svg"
                onClick={() => setMenuOn(false)}
            />
        </>
    )
}
